import { AuthConfig, AuthProfile, AuthUser } from "../type";
import jwtDecode from "jwt-decode";
import { logInfo, logWarn } from "./logger";
import SessionServiceClient from "../sessionsvc/SessionServiceClient";
import AmplifyAuth from "@aws-amplify/auth";

export default class UserTokenManager {
  private readonly userKey : string;

  private readonly authConfig : AuthConfig;

  constructor(userKey : string, authConfig : AuthConfig) {
    this.userKey = userKey;
    this.authConfig = authConfig;
  }

  /**
   * This is a custom function to check the expiration of the refreshToken.
   * This is because currently Amplify doesn't provide a way to check expiration for refresh_token.
   * https://github.com/aws-amplify/amplify-js/issues/4438
   * @param refreshToken 
   * @param clientId 
   * @returns a boolean i.e if the token is valid or not.
   */
  public async isRefreshTokenValid(refreshToken: string, clientId: string): Promise<boolean> {
    if (!refreshToken) {
      throw new Error('No refresh token provided');
    }
    try {
        const res = await fetch(`https://cognito-idp.${this.authConfig.region}.amazonaws.com/`, {
            headers: {
                'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
                'Content-Type': 'application/x-amz-json-1.1',
            },
            method: 'POST',
            body: JSON.stringify({
                ClientId: clientId,
                AuthFlow: 'REFRESH_TOKEN_AUTH',
                AuthParameters: {
                    REFRESH_TOKEN: refreshToken,
                },
            }),
        });
        const response = await res.json();
        if (response.AuthenticationResult.AccessToken && response.AuthenticationResult.IdToken) {
            logInfo('Operation to rotate tokens was ', 'SUCCESS');
            return true
        } 
        logWarn('Operation to rotate tokens did not go through', 'PARTIAL_FAILURE');
        return false
    } catch (error) {
        logWarn(`Operation to rotate tokens with refresh_token failed with ${error} `, 'FAILURE');
        return false
    }
  }

  /**
   * Takes authentication tokens as input and store them in local storage.
   */
  public setUserToStorage(idToken: string, accessToken: string, refreshToken: string, time: number) : void {
    let authProfile : AuthProfile | null = null;
    try {
      authProfile = jwtDecode(idToken);
    } catch (e) {
      // TODO handle exception better as we use this method in cognito flow
      // as currently this method is only used in Midway flow and AuthProfile object is not really used
      logWarn("AuthManager", "JWT token cannot be decoded")
    }

    const user : AuthUser = {
      id_token: idToken,
      access_token: accessToken,
      refresh_token: refreshToken,
      scope: "",
      token_type: "token",
      profile: authProfile,
      expires_at: time,
    };
    const userkey = this.userKey;

    if (userkey) {
      window.localStorage.setItem(userkey, JSON.stringify(user));
    }
  }

    public setNonceToStorage(rawNonce: string) : void {
        window.localStorage.setItem("sampleRawNonce", rawNonce);
    }

  /**
   * Retrieves user object and authentication tokens from browser local storage if exists.
   */
  public async getUserFromStorage() : Promise<AuthUser | null> {
    // New login page uses Amplify library to authenticate both internal and external user
    // and the tokens are stored with different keys in browser local storage.
    // To keep backward compatibility, the following code maps them to use the same key staring with "oidc"
    if (this.userKey && this.authConfig && this.authConfig.useSelfHostedLoginUI == true) {
      try {
        const amplifySession = await AmplifyAuth.currentSession();
        this.setUserToStorage(
          amplifySession.getIdToken().getJwtToken(),
          amplifySession.getAccessToken().getJwtToken(),
          amplifySession.getRefreshToken().getToken(),
          amplifySession.getIdToken().getExpiration());
      } catch (err) {
        // if there is error thrown, fallback to previous implementation to retrieve user tokens, there are 2 purposes:
        // 1. Not force user log out during the switch of new UI
        // 2. Maintain the current UI integration test behaviors
        logWarn('AuthManager', 'Current user session cannot be fetched by AmplifyAuth with error: ' + err);
      }
    }

    if (this.userKey && window.localStorage.getItem(this.userKey)) {
      const authUserString = window.localStorage.getItem(this.userKey);
      if (authUserString) {
        const user = JSON.parse(authUserString);
        const expireTime = user.expires_at;
        const currentTime = new Date().getTime()/1000;
        if (currentTime >= expireTime) {
          logInfo('AuthManager.token has expired', 'current time ' + currentTime.toString() + ' expire time '+ expireTime.toString());
          window.localStorage.removeItem(this.userKey);
          window.localStorage.removeItem(SessionServiceClient.sessSvcKey);
          window.location.assign(this.authConfig.redirectSignIn);
          return null;
        }
        return user;
      }
    }
    return null;
  }
}
